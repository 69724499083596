import {Button, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import React from "react";
import {LinkContainer} from "react-router-bootstrap"
import {$, changeLanguage, getLanguageRef, getLanguages} from "../Services/LanguageService";
import {Auth, Navigate} from "./PageWithHeader";

export default (props : {}&Auth&Navigate) => {
    return <Navbar bg="light" expand="lg" className="mb-5">
        <Container>
            <LinkContainer to="/"><Navbar.Brand><img alt="logo" height="30" width="30" className="d-inline-block align-top mx-1" src="/logo192.png"/>{$("title")}</Navbar.Brand></LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
                <Nav className="me-auto">
                    <LinkContainer to="/"><Nav.Link>{$("home")}</Nav.Link></LinkContainer>
                    <LinkContainer to="/favorites"><Nav.Link>{$("favorites")}</Nav.Link></LinkContainer>
                    <LinkContainer to="/about"><Nav.Link>{$("about")}</Nav.Link></LinkContainer>
                    <LinkContainer to="/help"><Nav.Link>{$("help")}</Nav.Link></LinkContainer>
                </Nav>
                {
                    props.auth.state[0]
                        ? <Button variant="secondary" onClick={() => {
                            props.auth.logout().then(() => props.navigate('/', {
                                replace: true
                            }))
                        }}>{$("logout")}</Button>
                        : <LinkContainer to="/login"><Button>{$("login")}</Button></LinkContainer>
                }
                <NavDropdown title={getLanguageRef()?.name}>
                    {getLanguages().map(lang => <NavDropdown.Item onClick={() => {
                        changeLanguage(lang.code)
                        props.navigate(document.location.pathname, {
                            replace: true
                        })
                    }}>{lang.name}</NavDropdown.Item>)}
                </NavDropdown>
            </Navbar.Collapse>
        </Container>
    </Navbar>
}
