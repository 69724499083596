import {
    Col,
    Container,
    Row
} from "react-bootstrap";
import { Geolocation } from '@capacitor/geolocation';
import React from "react";
import GoogleMapReact from "google-map-react";
import {Auth, Navigate, PageWithHeader} from "../Elements/PageWithHeader";
import {RenderMarker, DynamicMarker, Marker} from "../Elements/Marker";
import {Search} from "../Elements/Search";
import MapStyle from "../Assets/MapStyle.json";
import Config from "../Assets/Config.json";
import {$} from "../Services/LanguageService";


export class MainPage extends PageWithHeader<{}, {markers: RenderMarker[], geolocation:boolean, coords?: Marker}> {
    constructor(props : {}&Auth&Navigate) {
        super(props);
        this.state = {
            coords: Config.map.defaultPosition,
            markers: [],
            geolocation: false
        }
        this.updateMarkers.bind(this)
    }

    componentDidMount() {
        this.updateCoords()
    }

    pushMarker(marker : RenderMarker) {
        const oldState = {...this.state}
        oldState.markers.push(marker)
        this.setState(oldState)
    }

    updateCoords() {
        Geolocation.getCurrentPosition().then((position) => {
            let lat = position.coords.latitude;
            let long = position.coords.longitude;
            const marker = {
                lng: long,
                lat: lat
            };
            const state = {...this.state}
            state.geolocation = true
            this.setState(state)
            this.navigate(marker)
            this.pushMarker({ ...marker, component: () => <img
                    style={{transform: "translate(-50%, -90%)"}} alt="" height="42" width="42"
                    src={Config.map.positionIcon}/> })
        }).catch();
    }

    navigate(marker: Marker) {
        this.setState({markers: this.state?.markers, coords: marker})

    }

    clearMarkers() {
        const oldState = {...this.state}
        oldState.markers = []
        this.setState(oldState)
        this.updateCoords()
    }
    updateMarkers(markers: RenderMarker[]) {
        for (const marker of markers) {
            this.pushMarker(marker)
        }
    }

    override Content() {
        return <Container className="text-center">
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <div>
                        <h2>
                            {$('main_heading')}
                        </h2>
                    </div>
                </Col>
            </Row>

            <Row className="justify-content-md-center">
                <Col md="auto">
                    <p>
                        {$('secondary_heading')}
                    </p>
                </Col>
            </Row>
            <Search parent={this} setProducts={() => {}}
                    geoLocationEnabled={this.state === null ? false : this.state.geolocation}/>
        </Container>
    }

    override Bottom() {
        return <div style={{height: '100%', width: '100%'}} className="pt-5">
            <GoogleMapReact
                options={{
                    disableDefaultUI: true,
                    styles: MapStyle
                }}
                bootstrapURLKeys={{key: Config.map.googleKey}}
                center={(this.state && this.state.coords)}
                defaultZoom={18}>
                {((this.state && this.state.markers) ? this.state.markers.map((marker, index) => (
                    <DynamicMarker
                        key={index}
                        {...marker}
                    />
                )) : <></>)}
            </GoogleMapReact>
        </div>
    }
}
