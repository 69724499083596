import Config from "../Assets/Config.json";
import {useEffect, useState} from "react";

export interface Favorite {
    table : string,
    id : number
}

export type Profile = {
    email : string
    username : string
    firstName : string
    lastName : string
} | null

let g_token = localStorage.getItem('token')

async function g_authFetch(url : string, init : RequestInit|undefined) {
    return await fetch(`${Config.api.url}/${url}`, {
        ...(init||{}), headers: {
            'Authorization': `Bearer ${g_token}`
        }
    })
}


async function g_register(data : {firstName : string, lastName : string, username : string, email : string, password : string}) {
    try {
        const newToken = await fetch(`${Config.api.url}/auth/register`, {
            body: JSON.stringify({
                ...data
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(x => x.json())
        g_token = newToken
        localStorage.setItem('token', newToken)
        return true
    } catch {
        return false
    }
}

async function g_login(username : string, password : string) {
    try {
        const newToken = await fetch(`${Config.api.url}/auth/login`, {
            body: JSON.stringify({
                username,
                password
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(x => x.json())
        g_token = newToken
        localStorage.setItem('token', newToken)
        return true
    } catch {
        return false
    }
}

async function g_profile() {
    return (await g_authFetch('profile', {method: 'GET'}).then(x => x.json())) as Profile
}

async function g_favorites() {
    return (await g_authFetch('favorite/all', {method: 'GET'}).then(x => x.json())) as Favorite[]
}


async function g_favorite_add(favorite : Favorite) {
    return (await g_authFetch(`favorite/add/${encodeURIComponent(favorite.table)}/${encodeURIComponent(favorite.id)}`, {method: 'PUT'}))
}


async function g_favorite_remove(favorite : Favorite) {
    return (await g_authFetch(`favorite/remove/${encodeURIComponent(favorite.table)}/${encodeURIComponent(favorite.id)}`, {method: 'DELETE'}))
}

async function g_auth_destroy() {
    await g_authFetch(`auth/destroy`, {method: 'DELETE'})
    g_token = null
    localStorage.removeItem('token')
}

export function useAuth() {
    const state = useState(null as Profile|null)
    useEffect(() => {
        if (g_token) {
            g_profile().then(state[1])
        }
    }, [])
    return {
        state,
        async login(username : string, password : string) {
            try {
                if (await g_login(username, password)) {
                    state[1](await g_profile())
                    return true

                } else {
                    return false
                }
            } catch {
                return false
            }
        },
        async register(data : {firstName : string, lastName : string, username : string, email : string, password : string}) {
            try {
                if (await g_register(data)) {
                    state[1](await g_profile())
                    return true
                } else {
                    return false
                }
            } catch {
                return false
            }
        },
        async logout() {
            try {
                await g_auth_destroy()
                state[1](null)
                return true
            } catch {
                return false
            }
        }
    }
}

export function useFavorites() {
    const state = useState([] as Favorite[])
    const available = useState(false)
    useEffect(() => {
        if (g_token) {
            g_favorites().then((value) => {
                state[1](value)
                available[1](true)
            }).catch(() => {
                available[1](false)
            })
        } else {
            available[1](false)
        }
    }, [])
    return {
        available,
        state,
        async add(favorite : Favorite) {
            let newState = [...state[0]]
            newState.push(favorite)
            state[1](newState)
            await g_favorite_add(favorite)
        },
        async remove(favorite : Favorite) {
            let newState = state[0].filter(x => !(x.id === favorite.id && x.table === favorite.table))
            state[1](newState)
            await g_favorite_remove(favorite)
        }
    }
}
