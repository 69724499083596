import Config from "../Assets/Config.json";
import {Marker} from "../Elements/Marker";


export interface QueryCondition {
    type: 'equals' | 'not_null' | 'like' | 'group_start' | 'group_end'
    value?: any,
    field?: string,
    chain?: 'and' | 'or'
}

const fuelTypeToMk = Config.fuel.database;

export async function queryProducts(marker : Marker|undefined, fuelType : "Walking"|"Diesel"|"Petrol"|"NaturalGas", keywords: string[]) {
    const params = {
        "lat": marker?.lat,
        "lon": marker?.lng,
        "fuelType": fuelTypeToMk[fuelType],
        "keywords": keywords
    };
    return await fetch(`${Config.api.url}/products`, {
        body: JSON.stringify(params),
        method: 'post',
        headers: {
            'content-type': 'application/json'
        }
    }).then(response => response.json())
}

export async function query(table : string, conditions : QueryCondition[], limit? : number) {
    let url = `${Config.api.url}/select/${encodeURIComponent(table)}`
    if (limit) {
        url += '?limit=' + encodeURIComponent(limit)
    }
    return await fetch(url, {
        body: JSON.stringify(conditions),
        method: 'post',
        headers: {
            'content-type': 'application/json'
        }
    }).then(response => response.json())
}

export async function searchTable(table : string, fields: string[], keywords : string[]) {
    const conditions : QueryCondition[] = []
    for(const field of fields) {
        conditions.push({
            type: 'group_start'
        })
        for(const keyword of keywords) {
            conditions.push({
                type: 'like',
                field,
                value: '%' + keyword + '%',
                chain: 'and'
            })
        }
        conditions.push({
            type: 'group_end',
            chain: 'or'
        })
    }
    return await query(table, conditions)
}
