import {AutoPage} from "./AutoPage";
import Header from "./Header";
import {useAuth} from "../Services/AuthenticationService";
import {useNavigate} from "react-router-dom";
import {Footer} from "./Footer";

export type Auth = {auth: ReturnType<typeof useAuth>}
export type Navigate = {navigate : ReturnType<typeof useNavigate>}

export abstract class PageWithHeader<P,S> extends AutoPage<P&Auth&Navigate, S> {
    protected constructor(props: P&Auth&Navigate) {
        super(props);
        this.BeforeContent.push(() => <Header auth={this.props.auth} navigate={this.props.navigate}/>)
        this.AfterAll.push(()=><Footer/>)
    }
}
