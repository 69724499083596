import {Container} from "react-bootstrap";
import {$, getCountryCode} from "../Services/LanguageService";


function Disclaimer() {
    return <div>
        <Container className="d-flex d-md-none justify-content-center align-self-center">
            <img src={`/usaid_logo/${getCountryCode()}.png`} width="100%" className="d-block d-md-none" alt="USAID"/>
        </Container>
        <Container className="d-flex justify-content-around text-center">
            <img className="d-none d-md-flex justify-content-center align-self-center" src={`/usaid_logo/${getCountryCode()}.png`} width="250px" height="97px" alt="USAID"/>
            <span className="d-flex d-md-none">{$('disclaimer')}</span>
            <span className="d-none d-md-flex justify-content-center align-self-center">{$('disclaimer')}</span>

        </Container>
    </div>
}

export function Footer() {
    return <footer className="footer">
        {
            <Disclaimer/>
        }

    </footer>;
}
