import {$, postProcess} from "../Services/LanguageService";
import React from "react";
import {defaultImages} from "../Assets/DefaultAssets";
import {useFavorites} from "../Services/AuthenticationService";
import {Button} from "react-bootstrap";
import Config from '../Assets/Config.json';
export function Product({product, onClick, favorites} : { product:any, favorites:ReturnType<typeof useFavorites>, onClick: (() => any)|false }) {
    let priceDiff = product.oil_adjusted_price - product.price
    let favorite = !!favorites.state[0].find(x => x.table === 'pharmacies_products' && x.id === product.id)
    return <div className="row mb-3 border-bottom">
        <img alt={postProcess(product.name)} width="50" className="col-md-6 rounded float-left"
             onError={(ev) => ev.currentTarget.src = defaultImages["pharmacy_products"]}
             src={(product.hash && (`${Config.api.imagesUrl}/${product.hash}`)) || defaultImages["pharmacy_products"]}/>
        <div className="col-md-6">
            <b>{postProcess(product.name)}</b><br/>
            <span>{$('price')}: <b>{product.price}</b> {$('mkd')}</span> <br/>
            {
                priceDiff > 1
                    ? <><span>{$('fuel')}: <b>{priceDiff.toFixed(2)}</b> {$('mkd')}</span> <br/></>
                    : <></>
            }
            <span>{$('pharmacy')}: <a {...(onClick ? {href:"#", onClick:onClick} : {})}>{postProcess(product.pharmacy_name)}</a></span><br/>
            {
                (favorites.available[0]) ?
                <Button variant={favorite? "secondary" : "primary"} onClick={async (target) => {
                    try {
                        let old = favorite
                        favorite = !favorite;
                        (target as any).disabled = "disabled";
                        if (old) {
                            await favorites.remove({id: product.id, table: 'pharmacies_products'})
                        } else {
                            await favorites.add({id: product.id, table: 'pharmacies_products'})
                        }
                    } finally {
                        (target as any).disabled = undefined;
                    }
                }} className="mb-2">{favorite ? $('remove_favorite') : $('add_favorite')}</Button> : <></>
            }
        </div>
    </div>
}
