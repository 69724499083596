import {PageWithHeader} from "../Elements/Page";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import React from "react";
import {$} from "../Services/LanguageService";
import {Auth, Navigate} from "../Elements/PageWithHeader";

export class RegisterPage extends PageWithHeader<{}, {hasError: boolean, hasConsent: boolean}> {
    constructor(props : Auth&Navigate) {
        super(props);
        this.state = {
            hasError: false,
            hasConsent: false
        }
    }
    override Content(): JSX.Element {
        return <Container>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <div>
                        <h2>
                            {$('register_title')}
                        </h2>
                        <p style={{color: 'red', ...(this.state.hasError?{}:{display:'none'})}}>
                            {$('failed_register')}
                        </p>
                        <Form className="mt-5" onSubmit={
                            event => {
                                event.preventDefault()
                                const firstName = (event.target as any)[0].value as string
                                const lastName = (event.target as any)[1].value as string
                                const username = (event.target as any)[2].value as string
                                const password = (event.target as any)[3].value as string
                                const email = (event.target as any)[4].value as string

                                this.props.auth.register({
                                    firstName,
                                    lastName,
                                    username,
                                    password,
                                    email,
                                }).then(value => {
                                    if (value) {
                                        this.props.navigate('/')
                                    } else {
                                        this.setState({
                                            ...this.state,
                                            hasError: true
                                        })
                                    }
                                })
                            }
                        }>
                            <Form.Group className="mb-3" controlId="formBasicName">
                                <Form.Label>{$('first_name')}</Form.Label>
                                <Form.Control type="text" placeholder={$('first_name_placeholder')}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicLastName">
                                <Form.Label>{$('last_name')}</Form.Label>
                                <Form.Control type="text" placeholder={$('last_name_placeholder')}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicUsername">
                                <Form.Label>{$('username')}</Form.Label>
                                <Form.Control type="text" placeholder={$('username_placeholder')}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>{$('password')}</Form.Label>
                                <Form.Control type="password" placeholder={$('password_placeholder')}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>{$('email')}</Form.Label>
                                <Form.Control type="email" placeholder={$('email_placeholder')}/>
                            </Form.Group>
                            <Form.Check onClick={() => {
                                this.setState({
                                    ...this.state,
                                    hasConsent: !this.state.hasConsent
                                })}
                            } label={$('promotional_agree')} type="checkbox"/><br/>
                            <Button disabled={!this.state.hasConsent} variant="primary" type="submit">
                                {$('register')}
                            </Button>
                            <span className="mx-2"> {$('or')} </span>
                            <LinkContainer to="/login">
                                <a>
                                    {$('login')}
                                </a>
                            </LinkContainer>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    }
}
