import {PageWithHeader} from "../Elements/Page";
import {Container} from "react-bootstrap";
import React from "react";
import {$} from "../Services/LanguageService";

export class About extends PageWithHeader<{}, {}> {
    override Content(): JSX.Element {
        return <Container>
            <div>
                <h2>{$('about_app')}</h2>
                <p>{$('about_app_p1')}</p>
                <p>{$('about_app_p2')}</p>
                <p>{$('about_app_p3')}</p>
                <p>{$('about_app_p4')}</p>
                <p>{$('about_app_p5')}</p>
                <p>{$('about_p2')}</p>

                <a style={{textDecoration: "none"}} href="https://startier.co/">
                    <h2 className="d-flex">{$('about_startier')}
                        <img src="/startier.png" className="mt-1 mx-2" style={{height: "2rem"}} alt="startier"/>
                    </h2>
                </a>
                <p>{$('about_p1')}</p>

                <p>{$('about_startier_p1')}</p>
            </div>
        </Container>
    }
}
