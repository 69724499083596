import {PageWithHeader} from "../Elements/Page";
import {Col, Container, Row} from "react-bootstrap";
import React, {} from "react";
import {$} from "../Services/LanguageService";
import {Link} from "react-router-dom";

export class AlreadyLoggedIn extends PageWithHeader<{}, {}> {
    override Content(): JSX.Element {
        return <Container>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <div>
                        <h2>{$("already_logged_in")}</h2>
                        <Link to="/">{$("click_here")}</Link> {$("go_back_main_page")}
                    </div>
                </Col>
            </Row>
        </Container>
    }
}
