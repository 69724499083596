import {PageWithHeader} from "../Elements/Page";
import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {$} from "../Services/LanguageService";
import {useFavorites} from "../Services/AuthenticationService";
import {query} from "../Services/QueryService";
import {Product} from "../Elements/Product";

function FavoritesContainer() {
    const favorites = useFavorites();
    const [products, setProducts] = useState<any[] | null>(null)

    async function getFavorites() {
        const state = favorites.state[0]
        if (!favorites.state[0].length) {
            setProducts([])
        } else {
            const total = await query("pharmacies_products", state.map(x => {
                return {
                    type: "equals",
                    value: x.id,
                    chain: "or",
                    field: "id"
                }
            }))
            setProducts(total)
        }
    }


    useEffect(()=> {
        getFavorites().then()
    }, [favorites.state[0]])
    return <Container>
        {
            (products === null) ? <p>{$('searching')}...</p> : (
                (products.length === 0) ? (
                    <p>{$('nothing_found')}!</p>
                ) : (
                    products.map(product => <Product
                        favorites={favorites}
                        onClick={false} product={product}/>)
                )
            )
        }
    </Container>
}

export class Favorites extends PageWithHeader<{}, {}> {
    override Content(): JSX.Element {
        return <Container className="text-center">
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <div>
                        <h2>{$('favorites')}</h2>
                        <FavoritesContainer/>
                    </div>
                </Col>
            </Row>
        </Container>
    }
}
