import React from "react";

const content = {
    "flex": "0 1 auto"
}

const auto = {
    "flex": "1 1 auto"
}

export function Normal(elements : (() => JSX.Element)[]) {
    return () => <>{elements.map(element => <div style={content}>{element()}</div>)}</>
}

export function Auto(elements: (() => JSX.Element)[]) {
    return () => <>{elements.map(element => <div style={auto}>{element()}</div>)}</>
}

export function Box(elements: (() => JSX.Element)[]) {
    return () => <div style={{
        display: "flex",
        flexFlow: "column",
        height: "100vh",
    }}>{elements.map(element => element())}</div>
}

export function FullContent(elements: (() => JSX.Element)[], footer: (() => JSX.Element)[]) {
    return <>
        <>{elements.map(element => element())}</>
        <div style={{
            bottom: "0",
            left: "0",
            right: "0",
            zIndex: "0"
        }}>{footer.map(element => element())}</div>
    </>
}

export abstract class AutoPage<P,S> extends React.Component<P, S> {
    protected BeforeContent : (() => JSX.Element)[] = []
    protected AfterContent : (() => JSX.Element)[] = []
    protected BeforeBottom : (() => JSX.Element)[] = []
    protected AfterBottom : (() => JSX.Element)[] = []
    protected BeforeAll : (() => JSX.Element)[] = []
    protected AfterAll : (() => JSX.Element)[] = []

    abstract Content() : JSX.Element;
    Bottom() : JSX.Element {
        return <></>
    }
    Construct() : JSX.Element {
        return FullContent([Box([...this.BeforeAll,Normal([...this.BeforeContent, () => this.Content(), ...this.AfterContent]),
            Auto([...this.BeforeBottom, () => this.Bottom(), ...this.AfterBottom])])], [...this.AfterAll])
    }
    render() {
        return this.Construct()
    }
}
