import React, {useState} from "react";
import {defaultImages} from "../Assets/DefaultAssets";
import {Button, InputGroup, Offcanvas} from "react-bootstrap";
import {$, postProcess} from "../Services/LanguageService";
import Config from "../Assets/Config.json";

export function DisplayPlace({place} : {place:any}) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <img alt="pharmacy" height="42" width="42" className="rounded-circle" style={{transform: "translate(-50%, -50%)", "objectFit": "cover"}}
                 src={(place.hash_logo && (`${Config.api.imagesUrl}/${place.hash_logo}`))
                     || defaultImages[place.table as string] || defaultImages['place']} onClick={handleShow}
                onError={
                    (ev) => {
                       ev.currentTarget.src = defaultImages[place.table as string] || defaultImages['place']
                    }
                }/>

            {show ?
                <Offcanvas show={true} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{postProcess(place.name)}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {place.address && <><span>{$('address')}: <b>{postProcess(place.address)}</b></span><br/></>}
                        {place.municipality && <><span>{$('municipality')}: <b>{postProcess(place.municipality)}</b></span><br/></>}
                        {place.inhabited_place && <>
                            <span>{$('location')}: <b>{postProcess(place.inhabited_place)}</b></span><br/></>}
                        {place.phone_number && <><span>{$('phone_number')}: <a
                            href={'tel:'+place.phone_number}>{place.phone_number}</a></span><br/></>}
                        {place.phone_number2 && <><span>{$('phone_number2')}: <a href={'tel:'+place.phone_number2}>{place.phone_number2}</a></span><br/></>}
                        {place.fax_number && <><span>{$('fax_number')}: <b>{place.fax_number}</b></span><br/></>}
                        {place.working_hours &&
                            <>
                            <span>{$('working_hours')}:<br/>
                                <ul>
                                    {place.working_hours.map((time: any) => <li>{postProcess(time)}</li>)}
                                </ul>
                            </span>
                                <br/>
                            </>
                        }


                        {(place.website || place.facebook_url || place.instagram_url) && <><InputGroup>
                            {place.website && <Button href={place.website} variant="secondary">{$('website')}</Button>}
                            {place.facebook_url &&
                                <Button href={place.facebook_url} variant="secondary">Facebook</Button>}
                            {place.instagram_url &&
                                <Button variant="secondary" href={place.instagram_url}>Instagram</Button>}
                        </InputGroup><br/></>}

                    </Offcanvas.Body>
                </Offcanvas>
                : <></>
            }
        </>
    );
}

