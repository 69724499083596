import {Marker, RenderMarker} from "./Marker";
import React from "react";
import {MainPage} from "../Pages/Main";
import {DisplayPlace} from "./DisplayPlace";
import {$} from "../Services/LanguageService";
import Config from "../Assets/Config.json";

export interface Category<T> {
    name: string,
    tables: string[],
    fields: string[],
    handle: (result : any[], context: T) => any|void
}

export interface SearchContext {
    geoLocationEnabled : boolean;
    parent : MainPage;
    setProducts: (results: any[]) => void
}

function createPlace(name : string, tables: string[]) : Category<SearchContext> {
    return {
        name,
        tables: tables,
        fields: Config.categories.places.searchableFields,
        handle: (result : any[], ctx : SearchContext) => {
            const markers: RenderMarker[] = []
            for (const place of result) {
                if (place.lon && place.lat) {
                    markers.push({
                        lng: place.lon,
                        lat: place.lat,
                        component: () => <DisplayPlace place={place}/>
                    })
                }
            }
            ctx.parent.updateMarkers(markers)
        }
    }
}

export function distance(a : Marker, b: Marker) {
    return Math.sqrt(Math.pow(a.lng - b.lng, 2) + Math.pow(a.lat - b.lat, 2))
}

export async function Categories() : Promise<Category<SearchContext>[]> {
    return [
        {
            name: $('products'),
            tables: Config.categories.products.tables,
            fields: Config.categories.products.searchableFields,
            handle: async (result, context) => {
                context.setProducts(result.splice(0, 25))
            }
        },

        ...Config.categories.places.subcategories.map(sub => createPlace($(sub.$name), sub.tables))
    ]
}

export const transportMethods = Config.fuel.types.map(configType => {
    return {name: $(configType.$name), type: configType.type}
})
