import Macedonian from '../Assets/Locale/Macedonian.json';
import Albanian from '../Assets/Locale/Albanian.json';
import LetterConversion from "../Assets/LetterConversion.json";
import Config from "../Assets/Config.json";

interface Language {
    name : string
    code : string
    strings : string[][],
    latin: boolean
}

interface LanguageRef {
    name : string
    code : string,
    latin: boolean
}

function initializeLanguages(languages : Language[]) {
    const lang = new Map<string,Map<string,string>>()
    const names : LanguageRef[] = []
    for(const language of languages) {
        names.push({name:language.name, code:language.code, latin: language.latin})
        lang.set(language.code, new Map<string,string>(language.strings as any))
    }
    return {lang, names}
}

const defaultLanguage = Config.language.default;

const {lang:languageMap, names:languageNames} = initializeLanguages([Macedonian, Albanian])

function getLanguage(code? : string|null) {
    return languageMap.get(code || defaultLanguage) || languageMap.get(defaultLanguage)
}


let selectedCountryCode = localStorage.getItem('lang') || defaultLanguage

let languageRef = languageNames.find(lang => lang.code === selectedCountryCode)
    || languageNames.find(lang => lang.code === defaultLanguage)

export function getLanguageRef() {
    return languageRef
}

let operationalLanguage = getLanguage(localStorage.getItem('lang') || defaultLanguage)

const letterList = LetterConversion as any;

const mkToEn = new Map<string,string>(letterList)


function toNonLatin(value : string) {
    for(const letter of letterList) {
        value = value.replaceAll(letter[1], letter[0])
    }
    return value
}


export function preProcess(value? : string) {
    return [...value as string].map(c => {
        if (mkToEn.has(c)) {
            return mkToEn.get(c)
        }
        return c
    }).join('')
}

export function changeLanguage(target : string) {
    localStorage.setItem('lang', target);
    selectedCountryCode = target || defaultLanguage
    operationalLanguage = getLanguage(selectedCountryCode)
    languageRef = languageNames.find(lang => lang.code === selectedCountryCode)
        || languageNames.find(lang => lang.code === defaultLanguage)
    window.location.reload()

}


export function $(key : string) {
    return operationalLanguage?.get('$'+key) || ('$'+key)
}

export function getLanguages() {
    return languageNames;
}

export function getCountryCode() {
    return selectedCountryCode;
}

export function postProcess(value: string) {
    let latin = getLanguageRef()?.latin || false
    return latin ? value : toNonLatin(value)
}

