import {PageWithHeader} from "../Elements/Page";
import {Container} from "react-bootstrap";
import React from "react";
import {$} from "../Services/LanguageService";
import Config from "../Assets/Config.json";

export class Help extends PageWithHeader<{}, {}> {
    override Content(): JSX.Element {
        const phone = Config.support.phone
        const email = Config.support.email

        const showHelpContractInfo = phone && email

        return <Container>
            {
                showHelpContractInfo ?
                <div>
                    <h2>{$('help')}</h2>
                    <p>{$('issues_text')}:</p>
                    <ul>
                        <li>{$('email')}: <a href={'mailto:' + email}>{email}</a></li>
                        <li>{$('phone_number')}: <a href={'tel:' + phone}>{phone}</a></li>
                    </ul>
                </div>
                    : <></>
            }
            <div>
                <h2>{$('application_use')}</h2>
                <p>{$('application_use_p1')}</p>
                <p>
                    <ol>
                        <li>{$('application_use_p1_l1')}</li>
                        <li>{$('application_use_p1_l2')}</li>
                        <li>{$('application_use_p1_l3')}</li>
                        <li>{$('application_use_p1_l4')}</li>
                    </ol>
                </p>
                <p>{$('application_use_p2')}</p>
                <p>
                    <ol>
                        <li>{$('application_use_p2_l1')}</li>
                        <li>{$('application_use_p2_l2')}</li>
                    </ol>
                </p>
                <p>{$('application_use_p3')}</p>
            </div>
        </Container>
    }
}
