import "bootswatch/dist/litera/bootstrap.min.css";
import './App.css'
import {Helmet} from "react-helmet";
import React from 'react';
import {Routes, Route, useNavigate} from "react-router-dom";
import {LoginPage} from "./Pages/Login";
import {RegisterPage} from "./Pages/Register";
import {MainPage} from './Pages/Main'
import {$} from "./Services/LanguageService";
import {useAuth} from "./Services/AuthenticationService";
import {AlreadyLoggedIn} from "./Pages/AlreadyLoggedIn";
import {Favorites} from "./Pages/FavoritesPage";
import {About} from "./Pages/About";
import {Help} from "./Pages/Help";

function App() {
    const auth = useAuth()
    const navigate = useNavigate()
    return <>
        <Helmet>
            <title>{$('title')}</title>
        </Helmet>
        <Routes>
            <Route path="/" element={<MainPage navigate={navigate} auth={auth}/>}/>
            <Route path="login" element={auth.state[0] ? <AlreadyLoggedIn navigate={navigate} auth={auth}/>
                : <LoginPage navigate={navigate} auth={auth}/>} />
            <Route path="register" element={auth.state[0] ? <AlreadyLoggedIn navigate={navigate} auth={auth}/>
                : <RegisterPage navigate={navigate} auth={auth}/>} />
            <Route path="favorites" element={auth.state[0] ? <Favorites navigate={navigate} auth={auth}/>
                : <LoginPage navigate={navigate} auth={auth}/>} />
            <Route path="about" element={<About navigate={navigate} auth={auth}/>}/>
            <Route path="help" element={<Help navigate={navigate} auth={auth}/>}/>
        </Routes>
    </>
}

export default App;

