export interface Marker {
    lat: number;
    lng : number;
}

export type RenderMarker = Marker&{component : () => JSX.Element};

export function DynamicMarker(data : RenderMarker) {
    return data.component()
}
