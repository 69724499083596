import {PageWithHeader} from "../Elements/Page";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React, {} from "react";
import {LinkContainer} from "react-router-bootstrap";
import {$} from "../Services/LanguageService";
import {Auth, Navigate} from "../Elements/PageWithHeader";

export class LoginPage extends PageWithHeader<{}, {hasError: boolean}> {
    constructor(props : Auth&Navigate) {
        super(props);
        this.state = {
            hasError: false
        }
    }
    override Content(): JSX.Element {
        return <Container>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <div>
                        <h2>
                            {$('login_title')}
                        </h2>
                        <p style={{color: 'red', ...(this.state.hasError?{}:{display:'none'})}}>
                            {$('failed_login')}
                        </p>
                        <Form onSubmit={
                            event => {
                                event.preventDefault()
                                const username = (event.target as any)[0].value as string
                                const password = (event.target as any)[1].value as string
                                this.props.auth.login(username, password).then(value => {
                                    if (value) {
                                        this.props.navigate('/')
                                    } else {
                                        this.setState({
                                            hasError: true
                                        })
                                    }
                                })
                            }
                        } className="mt-5">
                            <Form.Group className="mb-3" controlId="formBasicUsername">
                                <Form.Label>{$('username')}</Form.Label>
                                <Form.Control type="text" placeholder={$('username_placeholder')}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>{$('password')}</Form.Label>
                                <Form.Control type="password" placeholder={$('password_placeholder')}/>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                {$('login')}
                            </Button>
                            <span className="mx-2"> {$('or')} </span>
                            <LinkContainer to="/register">
                                <a>
                                    {$('register')}
                                </a>
                            </LinkContainer>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    }
}
