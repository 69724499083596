import React, {useState} from "react";
import {$, preProcess} from "../Services/LanguageService";
import {searchTable,queryProducts} from "../Services/QueryService";
import {
    Button,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    FormControl,
    InputGroup,
    Offcanvas,
    Row
} from "react-bootstrap";
import {Categories, Category, SearchContext, transportMethods} from "./Categories";
import {Product} from "./Product";
import {useFavorites} from "../Services/AuthenticationService";

export function Search(searchContext : SearchContext) {
    const favorites = useFavorites();
    const [show, setShow] = useState(false)
    const [selectedCategory, setCategory] = useState<Category<SearchContext> | null>(null)
    const [selectedTransport, setTransport] = useState<{ name: string, type: string }>(transportMethods[0])
    const [products, setProducts] = useState<any[] | null>(null)
    const [categories, loadCategories] = useState<Category<SearchContext>[]>([]);
    const secondContext = {...searchContext}
    secondContext.setProducts = (results) => {
        if (results.length) {
            setShow(true)
            setProducts(results)
        }
    }

    const [input, setInput] = useState('')
    if (!categories.length) {
        Categories().then((response) => {
            loadCategories(response)
            setCategory(response[0])
        })
    }

    async function search(selectedC?: Category<SearchContext>, value?: string) {
        searchContext.parent.clearMarkers()
        let selected = selectedC ? [selectedC] : categories

        const en = preProcess(value)
        const keywords_any = en.split(' ')
        const keywords1 = keywords_any.map(keyword => ' ' + keyword + ' ')
        const keywords2 = keywords_any.map(keyword => ' ' + keyword)
        const keywords3 = keywords_any.map(keyword => keyword + ' ')

        async function step(arr: any[], words: string[], table: string, category: Category<SearchContext>) {
            const results = await searchTable(table, category.fields, words)
            for (const index in results) {
                results[index].table = table
                results[index].id = table + ':' + results[index].id
            }
            return arr.concat(results);
        }

        for (const category of selected) {
            let total: any[] = []

            if (category.tables.includes('pharmacies_products')) {
                total = await queryProducts(searchContext.parent.state.coords, selectedTransport.type as any, keywords_any)
            } else {
                for (const table of category.tables) {
                    // todo: optimize
                    total = await step(total, [en], table, category)
                    total = await step(total, keywords1, table, category)
                    total = await step(total, keywords2, table, category)
                    total = await step(total, keywords3, table, category)
                    total = await step(total, keywords_any, table, category)
                }
            }
            const matches: number[] = []
            const actualResults: any[] = []
            for (const entry of total) {
                if (matches.includes(entry.id)) continue;
                matches.push(entry.id)
                actualResults.push(entry)
            }

            category.handle(actualResults, secondContext)
        }
    }

    const searchCallback = () => search(selectedCategory as Category<SearchContext>, input)
    const handleKeyPress = (event : {key: string}) => {
        if(event.key === 'Enter'){
            searchCallback().then()
        }
    }
    return (
        <>
            <Row className="justify-content-center">
                <Col>
                    <Container className="w-75 d-none d-md-flex">
                        <InputGroup>
                            <FormControl
                                placeholder={$('search_placeholder')}
                                aria-label={$('search_text')}
                                aria-describedby="search"
                                value={input}
                                onKeyPress={(ev) => handleKeyPress(ev)}
                                onInput={e => setInput((e.target as unknown as { value: string }).value)}
                            />
                            <Button variant="primary"
                                    onClick={searchCallback}>
                                {$('search')}
                            </Button>

                            <DropdownButton
                                variant="secondary"
                                title={selectedCategory ? selectedCategory.name : ''}>
                                {categories.map((category) =>
                                    <Dropdown.Item onClick={() => setCategory(category)}>{category.name}</Dropdown.Item>
                                )}
                            </DropdownButton>
                            {(searchContext.geoLocationEnabled && selectedCategory?.tables.includes('pharmacies_products') ? <DropdownButton
                                variant="secondary"
                                title={selectedTransport.name}>
                                <>
                                    {transportMethods.map(method => <Dropdown.Item key={method.name}
                                                                                   onClick={() => setTransport(method)}>{method.name}</Dropdown.Item>)}
                                </>
                            </DropdownButton> : <></>)}

                        </InputGroup>
                    </Container>
                    <Container className="w-100 d-block d-md-none">
                        <FormControl
                            placeholder={$('search_placeholder')}
                            aria-label={$('search_text')}
                            aria-describedby="search"
                            onKeyPress={(ev) => handleKeyPress(ev)}
                            value={input}
                            onInput={e => setInput((e.target as unknown as { value: string }).value)}
                        />

                        <div className="text-center">
                            <InputGroup className="d-grid pt-2">
                                <DropdownButton
                                    variant="secondary"
                                    title={selectedCategory ? selectedCategory.name : ''}>
                                    {categories.map((category) =>
                                        <Dropdown.Item
                                            onClick={() => setCategory(category)}>{category.name}</Dropdown.Item>
                                    )}
                                </DropdownButton>
                            </InputGroup>
                            <InputGroup className="d-grid pt-2">
                                {(searchContext.geoLocationEnabled && selectedCategory?.tables.includes('pharmacies_products') ? <DropdownButton
                                    variant="secondary"
                                    title={selectedTransport.name}>
                                    <>
                                        {transportMethods.map(method => <Dropdown.Item key={method.name}
                                                                                       onClick={() => setTransport(method)}>{method.name}</Dropdown.Item>)}
                                    </>
                                </DropdownButton> : <></>)}
                            </InputGroup>
                            <InputGroup className="d-grid pt-2">

                                <Button variant="primary"
                                        onClick={searchCallback}>
                                    {$('search')}
                                </Button>
                            </InputGroup>
                        </div>
                    </Container>
                </Col>
            </Row>
            <Offcanvas show={show} onHide={() => setShow(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{$('results_for')}: {input}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {
                        (products === null) ? <p>{$('searching')}...</p> : (
                            (products.length === 0) ? (
                                <p>{$('nothing_found')}!</p>
                            ) : (
                                products.map(product => <Product
                                    favorites={favorites}
                                    onClick={() => {
                                        setShow(false)
                                        search(categories.find(x => x.tables.includes('pharmacies') && x.tables.length === 1) as Category<SearchContext>, product.pharmacy_name).then()
                                    }} product={product}/>)
                            )
                        )
                    }
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

